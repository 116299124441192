// extracted by mini-css-extract-plugin
export var animate = "home-module--animate--YRRQr";
export var banner = "home-module--banner--x7zNu";
export var banner_arrow = "home-module--banner_arrow--g+Jme";
export var banner_btn = "home-module--banner_btn--GDWap";
export var banner_cover = "home-module--banner_cover--egFoJ";
export var banner_cover_top = "home-module--banner_cover_top--kio5K";
export var banner_icon = "home-module--banner_icon--OpKdW";
export var banner_radial = "home-module--banner_radial--+ZkzR";
export var banner_scroll_txt = "home-module--banner_scroll_txt--2+czn";
export var banner_text = "home-module--banner_text--92O6l";
export var bx1 = "home-module--bx1--nFCjD";
export var bx1_header = "home-module--bx1_header--VzG1k";
export var bx1_txt = "home-module--bx1_txt--c8RY9";
export var bx2 = "home-module--bx2--25h9N";
export var bx2_header = "home-module--bx2_header--87vQF";
export var bx2_txt = "home-module--bx2_txt--EPoxY";
export var bx3 = "home-module--bx3--iQziE";
export var bx3_header = "home-module--bx3_header--KCl7H";
export var bx3_txt = "home-module--bx3_txt--IrzwB";
export var bx4 = "home-module--bx4--NBR4E";
export var bx4_header = "home-module--bx4_header--5QxPH";
export var bx4_txt = "home-module--bx4_txt--BBLar";
export var carousel = "home-module--carousel--8FfMB";
export var carousel2 = "home-module--carousel2--nrgGp";
export var carousel2_wrapper = "home-module--carousel2_wrapper--l0mIa";
export var carousel_image = "home-module--carousel_image--02Ew7";
export var carousel_wrapper = "home-module--carousel_wrapper--meeyN";
export var contactbx = "home-module--contactbx--Szu5j";
export var contactbx_btn = "home-module--contactbx_btn--5J59H";
export var contactbx_inner = "home-module--contactbx_inner--v0DqF";
export var contactbx_inner_content = "home-module--contactbx_inner_content--7GkVn";
export var contactbx_inner_h1 = "home-module--contactbx_inner_h1--nV1Qg";
export var contactbx_inner_p = "home-module--contactbx_inner_p--JsU1R";
export var dottedline1 = "home-module--dottedline1--uMfC2";
export var dottedline2 = "home-module--dottedline2--Aoaia";
export var fab = "home-module--fab--PET7x";
export var fifth_section_content = "home-module--fifth_section_content--zRE0H";
export var fifth_section_content_inner = "home-module--fifth_section_content_inner--7Qm9a";
export var fifth_section_content_inner_header = "home-module--fifth_section_content_inner_header--w3sgh";
export var fifth_section_content_inner_txt = "home-module--fifth_section_content_inner_txt--Txz6Y";
export var fifth_section_subcontent = "home-module--fifth_section_subcontent--D8fnt";
export var fifth_section_subcontent_header = "home-module--fifth_section_subcontent_header--soOpS";
export var fifth_section_subcontent_txt = "home-module--fifth_section_subcontent_txt--kagsZ";
export var first_section = "home-module--first_section--WeP9J";
export var first_section_content = "home-module--first_section_content--nc660";
export var first_section_content_inner = "home-module--first_section_content_inner--A7HxV";
export var first_section_content_inner_btn = "home-module--first_section_content_inner_btn--SP5Ea";
export var first_section_content_inner_header = "home-module--first_section_content_inner_header--A8nW8";
export var first_section_content_inner_text = "home-module--first_section_content_inner_text--TSVbj";
export var fourth_section_content = "home-module--fourth_section_content--UmYLL";
export var fourth_section_content_box = "home-module--fourth_section_content_box--xRYfj";
export var fourth_section_content_box_btn = "home-module--fourth_section_content_box_btn--0Ovnu";
export var fourth_section_content_box_inner_icon = "home-module--fourth_section_content_box_inner_icon--vwNXe";
export var fourth_section_content_box_inner_txt = "home-module--fourth_section_content_box_inner_txt--hAjg+";
export var fourth_section_content_inner = "home-module--fourth_section_content_inner--mQ4PV";
export var fourth_section_content_inner_header = "home-module--fourth_section_content_inner_header--sNpMk";
export var fourth_section_content_inner_images = "home-module--fourth_section_content_inner_images--pN6jI";
export var globeimg = "home-module--globeimg--w8CCm";
export var image_item2 = "home-module--image_item2--Xd8eM";
export var img1 = "home-module--img1--D8+SL";
export var img2 = "home-module--img2--EvqEP";
export var imgicon = "home-module--imgicon--eAudt";
export var inner_carousel = "home-module--inner_carousel--AJPTY";
export var inner_carousel2 = "home-module--inner_carousel2--OGEY-";
export var line = "home-module--line--Qx-Yl";
export var penimg = "home-module--penimg--6wyqk";
export var schoolimg = "home-module--schoolimg--xIJaG";
export var scroll = "home-module--scroll--8j7W3";
export var second_section_content = "home-module--second_section_content--AtKq1";
export var second_section_content_inner = "home-module--second_section_content_inner--BR0qk";
export var second_section_content_inner_header = "home-module--second_section_content_inner_header--4ruwF";
export var second_section_content_inner_text = "home-module--second_section_content_inner_text--UIqN0";
export var slide = "home-module--slide--3eCyq";
export var slider = "home-module--slider--ROVPQ";
export var slider_container = "home-module--slider_container--6GDMu";
export var slider_radial = "home-module--slider_radial--Et4Hf";
export var span = "home-module--span--4Dmd+";
export var tagimg = "home-module--tagimg--n+KR-";
export var third_section_content = "home-module--third_section_content--6nJ2U";
export var third_section_content_inner = "home-module--third_section_content_inner--Z4Bfj";
export var third_section_content_inner_header = "home-module--third_section_content_inner_header--gmMKV";
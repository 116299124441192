import p1 from '../static/p1.jpg'
import p2 from '../static/p2.jpg'
import p3 from '../static/p3.jpg'
import p4 from '../static/p4.jpg'
import p5 from '../static/p5.jpg'
import p6 from '../static/p6.jpg'
import p7 from '../static/p7.jpg'
import p8 from '../static/p8.jpg'
import p9 from '../static/p9.jpg'
import p10 from '../static/p10.jpg'
import p11 from '../static/p11.jpg'
import p12 from '../static/p12.jpg'

export default [p1, p2, p3, p4, p5, p6, p7, p8, p9, p10, p11, p12, p12]